import request from '@/utils/request'

/**
 * @description 上传
 * @param {Object} param data {Object} 传值参数
 */
export function getOssConfig (configId) {
  return request({
    url: '/common/get-oss-signature',
    method: 'get'
  })
}